@charset "UTF-8";

@import './variables';

// html {
//     font-size: 62.5%;
// }

html,
body {
    padding: 0;
    margin: 0;
    // font-family: 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    //     Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
        sans-serif, BlinkMacSystemFont, Helvetica, Arial, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
    // font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    background-color: #fff;
    // background-color: #0e0e0e;
}

a {
    color: inherit;
    text-decoration: none;
    font-size: 1.1rem;
}

* {
    box-sizing: border-box;
}

hr {
    left: 50%;
    height: 1px;
    width: 70px;
    content: ' ';
    margin: -2rem 0 0 -35px !important;
    position: absolute;
    background-color: $blue-400 !important;
}

.image-center {
    margin: 0 auto;
    justify-content: center;

    > p {
        font-style: italic;
    }
}

.card-content .title {
    font-size: 1.8rem;
}

.timeline-path {
    background-color: $blue-400;
    height: calc(100% - 6rem);
    position: absolute;
    left: 50%;
    width: 1px;
    z-index: 1;
}

.timeline-point {
    background-color: #fff;
    // background-color: $blue-400;
    border: 1px solid $blue-400;
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top: 2.5rem;
    // top: 50%;

    &.right {
        left: auto;
        right: -0.4rem;
    }

    &.left {
        right: auto;
        left: -0.3rem;
    }
}

.circle {
    width: 0.5rem;
    height: 0.5rem;
    background-color: $blue-400;
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5rem;
    position: relative;
    top: -0.2rem;
}

.tag.is-small:not(body) {
    font-size: 0.55rem;
}

.title {
    font-family: 'Roboto', sans-serif;
}
