@charset "UTF-8";

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue-100: #cfe2ff;
$blue-200: #9ec5fe;
$blue-300: #6ea8fe;
$blue-400: #3d8bfd;
$blue-500: #0d6efd;
$blue-600: #0a58ca;
$blue-700: #084298;
$blue-800: #052c65;
$blue-900: #212529;

/**
* BULMA
*/
$primary: #0e0e0e;
$info: $blue-500;
$dark: #0e0e0e;
$light: $gray-100;

// $gap: 0;
// $container-offset: 0;

$family-sans-serif: 'Open Sans', sans-serif;
$body-background-color: $gray-100;
